import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import TalkContent from "components/talks/TalkContent";
import TweetWrapper from "components/common/TweetWrapper";
import SEO from "components/layout/SEO";
export const _frontmatter = {
  "title": "The How And Why Of Automation",
  "path": "talks/how-and-why-of-automation",
  "shortInfo": "How automating mundane tasks can have an impact on our brain in the long-term",
  "position": 2,
  "thumbnail": "./thumbnail.png",
  "videoLength": "17min",
  "place": "GeekyAnts",
  "date": "22 June '19"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TalkContent title="The How And Why Of Automation" shortInfo="How automating mundane tasks can have an impact on our brain in the long-term" youtubeUrl="https://www.youtube.com/watch?v=A2qoJDBM1Ow" mdxType="TalkContent">
      <SEO title="The How And Why Of Automation - Chaitanya Deorukhkar" description="How automating mundane tasks can have an impact on our brain in the long-term" image="talks/automation.png" path="talks/how-and-why-of-automation" mdxType="SEO" />
      <h2>{`What we don't talk about`}</h2>
      <p>{`In the tech industry, we often invest our time and energy in doing things that would either make us earn more money, provide a better customer experience or make developers more productive. We rarely give it a thought on how our work is affecting our cognition. What can we do to improve a developer’s mental health? This talk made me dive deep into how much time developers spend on their jobs and what kind of long-term impact it can have based on the type of work we do.`}</p>
      <br />
      <p>{`It's not just extra vacation days and bonuses that are going to give more meaning to our lives but it's also the work we do every day that will define our long-term mental health.`}</p>
      <h2>{`Psychologist behind the psychology`}</h2>
      <p>{`This talk was created in collaboration with `}<a parentName="p" {...{
          "href": "https://www.linkedin.com/in/devanshi-goradia-a2bba0153/"
        }}>{`Devanshi Goradia`}</a>{`, MSc. Mphil. Clinical Psychology. Her scientific knowledge and deep understanding of how our jobs affect the brain, helped me understand and communicate it.`}</p>
      <br />
      <p>{`If you have been struggling with finding a work-life balance, or if you are experiencing a mental block, which invariably affects your functioning, I'd recommend you drop her a mail at `}<strong parentName="p"><a parentName="strong" {...{
            "href": "mailto:dev7goradia@gmail.com"
          }}>{`dev7goradia@gmail.com`}</a></strong>{`.`}</p>
      <br />
      <br />
      <br />
      <br />
      <h2>{`Featured Tweets`}</h2>
      <TweetWrapper mdxType="TweetWrapper">
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`⚙️ I spoke about why you should automate mundane tasks and it's impact on your brain 🧠 in collaboration with `}<a parentName="p" {...{
                "href": "https://twitter.com/Devgoradia7"
              }}>{`@Devgoradia7`}</a>{` (Clinical Psychologist) at the React Native & Flutter Meetup organized by `}<a parentName="p" {...{
                "href": "https://twitter.com/geekyants"
              }}>{`@geekyants`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/atJVnv7QnC"
              }}>{`https://t.co/atJVnv7QnC`}</a></p>{`— Chaitanya Deorukhkar (@_anothercoder) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/_anothercoder/status/1149344146877890560"
            }}>{`July 11, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Next we have `}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{` presenting on "The why & how of automation" in `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/ReactNative?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#ReactNative`}</a>{` for our coming meetup this Saturday. `}<br parentName="p"></br><br parentName="p"></br>{`The event schedule to be shared soon. `}<a parentName="p" {...{
                "href": "https://t.co/wWBZ8f3Qg2"
              }}>{`pic.twitter.com/wWBZ8f3Qg2`}</a></p>{`— GeekyAnts (@geekyants) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/geekyants/status/1140924000484261888"
            }}>{`June 18, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`For the past few days I've been reading research papers and understanding brain scans. Very different than what I'd usually do. `}<br parentName="p"></br><br parentName="p"></br>{`Why?`}<br parentName="p"></br><br parentName="p"></br>{`Stop by for my talk `}<a parentName="p" {...{
                "href": "https://twitter.com/ReactNativeBLR"
              }}>{`@ReactNativeBLR`}</a><a parentName="p" {...{
                "href": "https://twitter.com/FlutterBLR"
              }}>{`@FlutterBLR`}</a>{` at `}<a parentName="p" {...{
                "href": "https://twitter.com/geekyants"
              }}>{`@geekyants`}</a>{` to know more 🤫`}</p>{`— Chaitanya Deorukhkar (@_anothercoder) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/_anothercoder/status/1141733363931156480"
            }}>{`June 20, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Chaitanya telling everyone to automate things to stay younger. `}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/59Cuj0oTPR"
              }}>{`pic.twitter.com/59Cuj0oTPR`}</a></p>{`— Sanket Sahu (@sanketsahu) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/sanketsahu/status/1142380201696886794"
            }}>{`June 22, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Now it's time for `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/ReactNative?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#ReactNative`}</a>{` to shine. So we start with `}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{` talking on "The Why & How of Automation" `}<a parentName="p" {...{
                "href": "https://t.co/xv8ROPm3NK"
              }}>{`pic.twitter.com/xv8ROPm3NK`}</a></p>{`— React Native BLR (@ReactNativeBLR) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/ReactNativeBLR/status/1142377199301005312"
            }}>{`June 22, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Literally mind (Grey Matter) blowing talk by `}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{` at `}<a parentName="p" {...{
                "href": "https://twitter.com/FlutterBLR"
              }}>{`@FlutterBLR`}</a>{` . Gotta learn a lot from him. 🙌`}</p>{`— Nitish Sahani 💙 (@_nitishsahani_) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/_nitishsahani_/status/1142390734701527040"
            }}>{`June 22, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Few words`}<br parentName="p"></br>{`* Smooth`}<br parentName="p"></br>{`* Generic`}<br parentName="p"></br>{`* Attention Grabbing`}<br parentName="p"></br>{`* Funny`}<br parentName="p"></br>{`* Vision Focused`}<br parentName="p"></br>{`It's time to move to the REAL silicon valley for you. `}<a parentName="p" {...{
                "href": "https://t.co/UhTBFgzeAA"
              }}>{`https://t.co/UhTBFgzeAA`}</a></p>{`— Jitesh (@droidboy313) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/droidboy313/status/1151356996488011776"
            }}>{`July 17, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Saturday afternoon well spent with `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/flutter?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#flutter`}</a>{` folks! 😎 `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/FlutterMeetup?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#FlutterMeetup`}</a>{` at `}<a parentName="p" {...{
                "href": "https://twitter.com/geekyants"
              }}>{`@geekyants`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/vEKy3mAFna"
              }}>{`pic.twitter.com/vEKy3mAFna`}</a></p>{`— Pooja Bhaumik (@pooja_bhaumik) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/pooja_bhaumik/status/1142471353099943936"
            }}>{`June 22, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`That was the best talk so far `}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{` `}<br parentName="p"></br>{`Really entertainment and to the point. Loved it.`}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/rnblr?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#rnblr`}</a></p>{`— Shad Mirza (@iamshadmirza) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/iamshadmirza/status/1142380634754568192"
            }}>{`June 22, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`👏🏻 Kudos to our speakers & to all the attendees for making `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/ReactNative?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#ReactNative`}</a>{` & `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/Flutter?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#Flutter`}</a>{` meetup successful.`}<br parentName="p"></br><br parentName="p"></br>{`Entire event video will be live soon! `}<a parentName="p" {...{
                "href": "https://t.co/WdEAGioy8V"
              }}>{`pic.twitter.com/WdEAGioy8V`}</a></p>{`— React Native BLR (@ReactNativeBLR) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/ReactNativeBLR/status/1143048719077527552"
            }}>{`June 24, 2019`}</a></blockquote></p>
      </TweetWrapper>
    </TalkContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      